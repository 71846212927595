import consumer from "./consumer"

consumer.subscriptions.create({ channel: "DiscussionChannel"}, {
  collection: function() {
    return $("[data-channel='discussions']");
  },
  connected: function() {
    setTimeout((function(_this) {
        _this.followCurrentObject();
        _this.installPageChangeCallback();
    })(this), 1000);
  },
  received: function(data) {
    console.log(data)
    if (!this.userIsCurrentUser(data.discussion)) {
      this.collection().append(data.discussion);
      $("#discussion_scrollable").scrollTop($("#discussion_scrollable")[0].scrollHeight);
    }
  },
  userIsCurrentUser: function(discussion) {
    return $(discussion).attr('data-user-id') === $('meta[name=current-user]').attr('id');
  },
  followCurrentObject: function() {
    var objectId;
    var objectType;
    if ((objectId = this.collection().data('object-id')) && (objectType = this.collection().data('object-type'))) {
      return this.perform('follow', {
        object_id: objectId,
        object_type: objectType
      });
    } else {
      return this.perform('unfollow');
    }
  },
  installPageChangeCallback: function() {
    if (!this.installedPageChangeCallback) {
      this.installedPageChangeCallback = true;
      return $(document).on('turbolinks:load', function() {
        return followCurrentObject();
      });
    }
  }
    
})

