import Quill from 'quill';
import 'quill/dist/quill.snow.css';

global.Quill = Quill;

var formats = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula'
  // 'image'
  // 'video'
];

$(document).ready(()=> {
  initQuill();
  $("#discussion_scrollable").scrollTop($("#discussion_scrollable")[0].scrollHeight);
});

function initQuill(){
  $('.quillerize').each(function(){
    var hidden_field = this;
    var container = $('<div>'+ this.value +'</div>');
    container.insertAfter(this);
    var quill = new Quill(container[0], {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }],
          [{ size: [] }],
          [
            'bold', 'italic', 'underline', 'strike',
            { 'script': 'super'},
            { 'script': 'sub' },
            'code', 'link'
          ],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: ['center', 'right', 'justify', false] }],
          [{ indent: '-1'}, { indent: '+1' }]
        ]
      },
      theme: 'snow',
      formats: formats
    });

    $('#bee-message-btn').on('click', function(){
      quill.focus();
    });

    $( document ).ready(function() {
      let url = window.location.href;
      if(url.includes("#bee-message-btn")){
        $('#bee-message-btn').trigger('click');
        quill.focus();
      }
    });

    $(this).closest('form').find('input[type=submit]').click(function(){
      hidden_field.value = quill.root.innerHTML;
      return true;
    });
  });
  $('.quillerize-inline').each(function(){
    var hidden_field = this;
    var container = $('<div>'+ this.value +'</div>');
    container.insertAfter(this);
    var quill = new Quill(container[0], {
      modules: {
        keyboard: {
          bindings: {
            handleEnter: {
              key: 13,
              handler: () => {$(this).closest('form').find('input[type=submit]').click()},
            }
          }
        },
        toolbar: [
          ['bold', 'italic', 'strike'],
          ['link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['blockquote', 'code-block']
        ]
      },
      theme: 'snow',
      formats: formats
    });

    $('#bee-message-btn').on('click', function(){
      quill.focus();
    });

    $( document ).ready(function() {
      let url = window.location.href;
      if(url.includes("#bee-message-btn")){
        $('#bee-message-btn').trigger('click');
        quill.focus();
      }
    });

    $(this).closest('form').find('input[type=submit]').click(function(){
      hidden_field.value = quill.root.innerHTML;
      return true;
    });
  });

}
