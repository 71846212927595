// require('datatables.net-bs')(window, $)
require('datatables.net-bs4')(window, $)
require('datatables.net-buttons-bs4')(window, $)
require('datatables.net-buttons/js/buttons.colVis.js')(window, $)
require('datatables.net-buttons/js/buttons.html5.js')(window, $)
require('datatables.net-buttons/js/buttons.print.js')(window, $)
require('datatables.net-responsive-bs4')(window, $)
require('datatables.net-select-bs4')(window, $)

$(document).ready(()=> {
  $('#bees-datatable').dataTable({
    language: {
      url: $('#bees-datatable').data('i18n')
    },
    processing: true,
    serverSide: true,
    drawCallback: function() {
      if (this.api().page.info().pages <= 1) {
        $('#bees-datatable_wrapper .pagination').hide();
      }

      $('tbody tr').on('click',function(e) {
        let username = $(this).find('a.username').attr('id');
        let bees_url = `${$('tr#bees-url').data('link')}/${username}`;
        window.location = bees_url;
      });
    },
    ajax:{
      url: $('#bees-datatable').data('source')
    },
    order: [[ 1, "asc" ]],
    columns: [
      {data: 'picture'},
      {data: 'name'},
      {data: 'username'},
      {data: 'linkedin', className: 'linkedin'},
      {data: 'slack', className: 'slack'},
      {data: 'craft'},
      {data: 'description'},
      {data: 'message'},
      {data: 'seperator'},
    ],
    columnDefs: [
      { className: "picture", "targets": [ 0 ] },
      { className: "name", "targets": [ 1 ] },
      { className: "username", "targets": [ 2 ] },
      { className: "linkedin-icon", "targets": [ 3 ] },
      { className: "slack-icon", "targets": [ 4 ] },
      { className: "craft", "targets": [ 5 ] },
      { className: "description", "targets": [ 6 ] },
      { className: "message", "targets": [ 7 ] },
      { className: "seperator", "targets": [ 8 ] },
    ]
});

  $('#hives-datatable').dataTable({
    language: {
      url: $('#hives-datatable').data('i18n')
    },
    conditionalPagddding: true,
    processing: true,
    serverSide: true,
    drawCallback: function() {
      if (this.api().page.info().pages <= 1) {
        $('#hives-datatable_wrapper .pagination').hide();
      }

      $('tbody tr').on('click',function(e) {
        let shortname = $(this).find('a.shortname').attr('id');
        let hives_url = `${$('tr#hives-url').data('link')}/${shortname}`;
        window.location = hives_url;
      });
    },
    ajax:{
      url: $('#hives-datatable').data('source')
    },
    pagingType: 'full_numbers',
    order: [[ 1, "asc" ]],
    columns: [
      {data: 'picture', className: 'picture'},
      {data: 'name', className: 'name'},
      {data: 'description', className: 'description'},
      {data: 'message', className: 'message'},
      {data: 'seperator', className: 'seperator'},
      {data: 'dt_actions', className: 'text-right'},
  ]});

  var project_table = $('#projects-datatable').dataTable({
    initComplete: function () {

      if ($(this).data('show_hive')==false){
        this.api().columns(0).visible(false);
      }
      if ($(this).data('show_bee')==false){
        this.api().columns(1).visible(false);
      }
      if ($(this).find('tbody tr').length<=1) {
        $('#projects-datatable_info').hide();
        $('#projects-datatable_paging').hide();
        $('#projects-datatable_length').hide();
        $('#projects-datatable_filter').hide();
      }else{
        this.api().columns([1]).every( function () {
          var column = this;
          var select = $('<select><option value=""></option></select>')
          // .appendTo( $(column.footer()).empty() )
          .appendTo( $(column.header()) )
          .on( 'change', function () {
            var val = $.fn.dataTable.util.escapeRegex(
              $(this).val()
            );

            column
              .search( val ? '^'+val+'$' : '', true, false )
              .draw();
          } );
          select.on('click', function(e) {
            e.stopPropagation();
          });
          column.data().unique().sort().each( function ( d, j ) {
            data = d.replace( /<.*?>/g, '' )
            if(data!='') {
              select.append( '<option value="'+data+'">'+data+'</option>' )
            }
          } );
        } );
      }
    },

    language: {
      url: $('#projects-datatable').data('i18n')
    },
    conditionalPagddding: true,
    processing: true,
    serverSide: true,
    drawCallback: function() {
      if (this.api().page.info().pages <= 1) {
        $('#projects-datatable_wrapper .pagination').hide();
      }

      $('tbody tr').on('click',function(e) {
        let project_url = `${$('tr#projects-url').data('link')}/${$(this).attr('id')}`
        window.location = project_url;
      });
    },
    ajax:{
      url: $('#projects-datatable').data('source')
    },
    pagingType: 'full_numbers',
    order: [[0, 'asc'], [3, 'asc']],
    columns: [
      {data: 'name', className: 'name'},
      {data: 'hive', className: 'hive', visible: true},
      {data: 'status', className: 'status'},
      {data: 'description', className: 'description'},
      {data: 'craft', className: 'craft'},
      {data: 'seperator', className: 'seperator'},
      {data: 'dt_actions'},
  ]});

  // Toggle sort icon
  let tableHeader = $('table.dataTable th');

  tableHeader.on('click', function(){
    tableHeader.each(element => {
      $(tableHeader[element]).find('svg').remove();
    });

    if($(this).children().length <= 0) {
      $(this).append('<i class="fa fa-sort ml-1"></i>')
    }
  });
});
